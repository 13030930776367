import { useState, useEffect, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { useUser } from "@/components/providers/user-provider";
import { Skeleton } from "@/components/ui/skeleton";
import {
  getVaultPlaybooks,
  createPlaybookAndQuestionnaire,
  completeQuestionnaire,
} from "../api";
import {
  createLinkToken,
  exchangePublicTokenForAccessToken,
} from "@/app/vault/api";
import { FinancialOrganizationDialog } from "../dialogs/financial-organization-dialog";
import { FinancialOrganizationPlaybookDialog } from "../dialogs/financial-organization-playbook-dialog";
import { LoaderCircle, Check } from "lucide-react";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "sonner";
import { Badge } from "@/components/ui/badge";

export function OrganizeMoney() {
  const [playbook, setPlaybook] = useState(null);
  const [openQuestionnaire, setQuestionnaireOpen] = useState(false);
  const [creatingPlaybook, setCreatingPlaybook] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openPlaybook, setPlaybookOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const { user } = useUser();
  const [linkToken, setLinkToken] = useState(null);
  const [loadingLinkToken, setLoadingLinkToken] = useState(false);

  const generateToken = useCallback(async () => {
    try {
      if (products.length < 1) return;
      setLoadingLinkToken(true);
      const { data } = await createLinkToken(products);
      setLinkToken(data.link_token);
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    }
  }, [products]);

  useEffect(() => {
    const init = async () => {
      generateToken();
    };
    init();
  }, [generateToken]);

  const onSuccess = useCallback(async (public_token) => {
    try {
      const response = await exchangePublicTokenForAccessToken({
        public_token,
        flock_id: user.active_flock_id,
      });
      reload();
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    }
  }, []);

  const { open: openPlaid, ready: plaidReady } = usePlaidLink({
    onSuccess,
    token: linkToken,
  });

  useEffect(() => {
    if (linkToken && plaidReady) {
      openPlaid();
    }
  }, [plaidReady, linkToken, openPlaid]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oauthStateId = urlParams.get("oauth_state_id");

    if (oauthStateId) {
      openPlaid(); // Resume Plaid Link
    }
  }, [openPlaid]);

  useEffect(() => {
    getVaultPlaybooks()
      .then((response) => {
        const organize_playbook = response.data.find((playbook) => {
          return playbook.intent === "organize_money";
        });

        setPlaybook(organize_playbook);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.active_flock_id]);

  function handleCreatePlaybook() {
    setCreatingPlaybook(true);
    createPlaybookAndQuestionnaire({ intent: "organize_money" })
      .then((response) => {
        setPlaybook(response.data);
        setQuestionnaireOpen(true);
      })
      .finally(() => {
        setCreatingPlaybook(false);
      });
  }

  function handleCompleteQuestionnaire(questions) {
    completeQuestionnaire({ playbookId: playbook.id, questions }).then(
      (res) => {
        setPlaybook(res.data);
        setQuestionnaireOpen(false);
        setPlaybookOpen(true);
      }
    );
  }

  if (loading) {
    return <LoadingCard />;
  }

  return (
    <>
      <div className="border rounded-md shadow w-full mt-4 p-4 flex flex-col gap-1 transition-all duration-300 ease-in-out">
        <div className="flex gap-2 items-center">
          <h2 className="text-lg font-semibold">Organize my money</h2>
          {playbook?.status === "complete" && (
            <Badge className="rounded-full" variant="outline">
              <Check className="w-3 h-3 mr-2" /> Completed
            </Badge>
          )}
        </div>
        <p className="text-muted-foreground text-sm max-w-[480px]">
          {playbook?.status !== "pending"
            ? "Start organizing your money by reviewing your actions."
            : "Answer our quick 8 question questionnaire to get a list of items that you need to organize."}
        </p>
        <div className="flex my-2 gap-4">
          {playbook && playbook.status !== "pending" ? (
            <Button onClick={() => setPlaybookOpen(true)}>
              {playbook.status === "complete" ? "Review Actions" : "My Actions"}
            </Button>
          ) : (
            <Button
              disabled={creatingPlaybook}
              onClick={() => {
                if (playbook) {
                  setQuestionnaireOpen(true);
                  return;
                }
                handleCreatePlaybook();
              }}
              className="w-fit rounded-full"
              variant="outline"
            >
              {creatingPlaybook && (
                <LoaderCircle className="animate-spin mr-2 w-4 h-4" />
              )}
              💰 Get Started
            </Button>
          )}
        </div>
      </div>
      <FinancialOrganizationDialog
        questionnaire={playbook?.questionnaire}
        open={openQuestionnaire}
        setOpen={setQuestionnaireOpen}
        handleCompleteQuestionnaire={handleCompleteQuestionnaire}
      />

      <FinancialOrganizationPlaybookDialog
        openPlaid={openPlaid}
        plaidReady={plaidReady}
        setProducts={setProducts}
        open={openPlaybook}
        setOpen={setPlaybookOpen}
        playbook={playbook}
      />
    </>
  );
}

function LoadingCard() {
  return (
    <div className="border rounded-md shadow w-full mt-4 p-4 flex flex-col gap-1 transition-all duration-300 ease-in-out">
      <div className="flex justify-between items-center">
        <Skeleton className="w-[50%] h-6" />
      </div>

      <Skeleton className="w-full h-4" />

      <div className="flex my-2 gap-4">
        <Skeleton className="w-[120px] h-8" />
      </div>
    </div>
  );
}
