import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Award, Check, Pen } from "lucide-react";
import { useUser } from "@/components/providers/user-provider";
import { StoryCanvas } from "@/app/stories/components/story-canvas";
import { getStoryOfTheWeek } from "./api";
import { createStory, getPeople } from "@/app/stories/api";
import { set } from "date-fns";

export function StoryOfWeek() {
  const { user } = useUser();
  const [storyOpen, setStoryOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPeople, setLoadingPeople] = useState(true);
  const [loadingCreateStory, setLoadingCreateStory] = useState(false);
  const [prompt, setPrompt] = useState();
  const [people, setPeople] = useState([]);
  const [story, setStory] = useState();
  const [reloadCount, setReloadCount] = useState(0);

  function reload() {
    setReloadCount(reloadCount + 1);
  }

  useEffect(() => {
    getStoryOfTheWeek().then((res) => {
      setPrompt(res.data.prompt);
      setStory(res.data.story);
      setLoading(false);
    });
  }, [user.active_flock_id, reloadCount]);

  function handleCreateStory() {
    setLoadingCreateStory(true);
    createStory(prompt.id).then((response) => {
      setStory(response.data);
      setLoadingCreateStory(false);
      setStoryOpen(true);
    });
  }

  useEffect(() => {
    getPeople().then((res) => {
      setPeople(res.data);
      setLoadingPeople(false);
    });
  }, [user.active_flock_id]);

  return (
    <>
      <div className="w-[270px] border rounded-md items-center flex-col flex">
        <div className="w-full h-[180px] rounded-md bg-muted flex flex-col gap-4 justify-center items-center">
          <img
            src="/illustrations/book-illustration.png"
            className="w-[160px] relative top-[-40px]"
          />
          <div className="relative top-[-24px] text-center text-sm text-muted-foreground flex justify-center gap-1 items-center">
            <Award className="w-3 h-3" />
            Story of the week
          </div>
        </div>
        <div className="px-2">
          {loading ? (
            <div className="flex flex-col my-4 gap-2 items-center">
              <Skeleton className="h-4 w-full" />{" "}
              <Skeleton className="h-4 w-[50%]" />
            </div>
          ) : (
            <h3 className="text-center font-semibold mt-4">{prompt?.title}</h3>
          )}

          {(loading || loadingPeople) && (
            <Skeleton className="h-8 w-full my-4" />
          )}

          {!loading && story && story.status === "complete" && (
            <div className="border rounded-sm flex items-center justify-center p-2 my-4">
              <Check className="mr-2 w-4 h-4" />{" "}
              <span className="text-sm">Completed</span>
            </div>
          )}

          {!loading &&
            !loadingPeople &&
            story &&
            story.status !== "complete" && (
              <Button
                onClick={() => setStoryOpen(true)}
                className="my-4 w-full shadow-none"
                variant="outline"
              >
                <Pen className="w-4 h-4 mr-2" />
                Resume Writing
              </Button>
            )}

          {!loading && !loadingPeople && !story && (
            <Button
              onClick={handleCreateStory}
              className="my-4 w-full shadow-none"
              variant="outline"
            >
              <Pen className="w-4 h-4 mr-2" />
              Start Writing
            </Button>
          )}
        </div>
      </div>
      {!loading && !loadingPeople && (
        <StoryCanvas
          open={storyOpen}
          setOpen={() => {
            setStoryOpen(false);
          }}
          prompt={prompt}
          story={story}
          users={people}
          reload={reload}
        />
      )}
    </>
  );
}
