import { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import {
  X,
  CircleDashed,
  CircleCheck,
  Save,
  LoaderCircle,
  Plus,
} from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useUser } from "@/components/providers/user-provider";
import { getPeople } from "@/app/vault/api";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { toast } from "sonner";
import { ItemCreationDialog } from "@/app/vault/components/item-creation-dialog";
export function SkippedOnboarding() {
  const { user, updatePhoto, updateUser } = useUser();
  const [reloadCount, setReloadCount] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [birthday, setBirthday] = useState(user.date_of_birth || "");
  const [people, setPeople] = useState([]);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState();
  const [addPersonOpen, setAddPersonOpen] = useState(false);
  const fileInputRef = useRef();

  function reload() {
    toast.success("Person added successfully.");
    setReloadCount(reloadCount + 1);
  }

  useEffect(() => {
    getPeople({}).then((res) => {
      setPeople(res.data);
    });
  }, [user]);

  useEffect(() => {
    setPreview(user.avatar_url);
  }, [user, reloadCount]);

  const handleFileUpload = (event) => {
    const temp_file = event.target.files[0];
    setFile(temp_file);

    if (temp_file) {
      setPreview(URL.createObjectURL(temp_file));
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      setUploading(true);
      await updatePhoto(formData);
      toast.success("Image has been uploaded.");
      setFile(null);
      fileInputRef.current.value = "";
    } catch (error) {
      toast.error("Error Uploading image. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleUpdateBirthday = async () => {
    try {
      setUploading(true);
      await updateUser({ date_of_birth: birthday });
      toast.success("Birthday added successfully.");
    } catch (error) {
      toast.error("Error adding birthday. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const photoUploaded = !!user.avatar_url;
  const birthdayAdded = !!user.date_of_birth;
  const personAdded = people.length > 1;
  const conditions = [photoUploaded, birthdayAdded, personAdded];
  const trueCount = conditions.filter(Boolean).length;

  useEffect(() => {
    if (trueCount === 3) {
      setExpanded(false);
    }
  }, [trueCount]);

  return (
    <div className="border rounded-md shadow w-full mt-8 p-4 flex flex-col gap-1 transition-all duration-300 ease-in-out">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold ">Skipped Onboarding?</h2>
      </div>
      <p className="text-muted-foreground text-sm">
        Follow our quick walk through guide to get up and running with Tapestry.
      </p>
      <div className="flex my-2 gap-4">
        {trueCount === 3 ? (
          <Button
            onClick={() => updateUser({ onboarding_status: "completed" })}
            className="w-fit rounded-full"
            variant="outline"
          >
            🎉 Mark Onboarding as Complete
          </Button>
        ) : (
          <Button
            onClick={() => setExpanded(!expanded)}
            className="w-fit rounded-full"
            variant="outline"
          >
            {expanded ? "✌️ Close Onboarding" : "🚀 Start Onboarding"}
          </Button>
        )}
        <Badge className="rounded-full" variant="secondary">
          {" "}
          {trueCount} / 3 completed
        </Badge>
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          expanded ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="w-full h-[200px]">
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem
              className="border-none hover:bg-gray-50 rounded-md px-2"
              value="item-1"
              disabled={photoUploaded}
            >
              <AccordionTrigger className="hover:no-underline">
                <div className="flex items-center gap-2">
                  {photoUploaded ? (
                    <CircleCheck className="w-5 h-5" />
                  ) : (
                    <div className="w-5 h-5 border-2 border-dashed hover:border-solid border-gray-400 hover:border-gray-600 rounded-full" />
                  )}
                  <span>Add your photo</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex gap-2 items-center">
                  <Avatar>
                    <AvatarImage className="object-cover" src={preview} />
                    <AvatarFallback>{user.first_name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="flex gap-2">
                    <form className="flex flex-col gap-4">
                      <Input
                        className="shadow-none"
                        ref={fileInputRef}
                        id="paystubs"
                        onChange={handleFileUpload}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                      />
                    </form>
                    {file && (
                      <Button
                        onClick={handleSubmit}
                        disabled={uploading}
                        className="text-sm rounded-full"
                        variant="outline"
                      >
                        {uploading ? (
                          <LoaderCircle className="w-4 h-4 mr-2 animate-spin" />
                        ) : (
                          <Save className="w-4 h-4 mr-2" />
                        )}
                        Save Photo
                      </Button>
                    )}
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              className="border-none hover:bg-gray-50 rounded-md px-2"
              value="item-2"
              disabled={birthdayAdded}
            >
              <AccordionTrigger className="hover:no-underline">
                <div className="flex items-center gap-2">
                  {birthdayAdded ? (
                    <CircleCheck className="w-5 h-5" />
                  ) : (
                    <div className="w-5 h-5 border-2 border-dashed hover:border-solid border-gray-400 hover:border-gray-600 rounded-full" />
                  )}
                  <span>Add your birthday</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex gap-2">
                  <Input
                    className="w-fit shadow-none"
                    type="date"
                    id="dob"
                    onChange={(e) => setBirthday(e.target.value)}
                    value={birthday}
                  />
                  <Button
                    onClick={handleUpdateBirthday}
                    className="text-sm rounded-full"
                    variant="outline"
                  >
                    {uploading ? (
                      <LoaderCircle className="w-4 h-4 mr-2 animate-spin" />
                    ) : (
                      <Save className="w-4 h-4 mr-2" />
                    )}
                    Save Birthday
                  </Button>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              className="border-none hover:bg-gray-50 rounded-md px-2"
              value="item-3"
              disabled={personAdded}
            >
              <AccordionTrigger className="hover:no-underline">
                <div className="flex items-center gap-2">
                  {personAdded ? (
                    <CircleCheck className="w-5 h-5" />
                  ) : (
                    <div className="w-5 h-5 border-2 border-dashed hover:border-solid border-gray-400 hover:border-gray-600 rounded-full" />
                  )}
                  <span>Add a person</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <Button
                  onClick={() => setAddPersonOpen(true)}
                  className="text-sm rounded-full"
                  variant="outline"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add your first person
                </Button>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <ItemCreationDialog
        item="person"
        open={addPersonOpen}
        onClose={() => setAddPersonOpen(false)}
        reload={reload}
      />
    </div>
  );
}
