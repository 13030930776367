import dynamic from "next/dynamic";
import { useRef, useState, useEffect } from "react";
import { useDebounce } from "react-use";
const Editor = dynamic(() => import("@/components/editor"), { ssr: false });
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { VoiceRecorder } from "@/app/write/[id]/components/voice-recorder";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { toast } from "sonner";
import { Badge } from "@/components/ui/badge";
import { saveStory, saveRecording } from "@/app/write/[id]/api";
import { updateStory } from "../api";
import { Plus, Save, Sparkle } from "lucide-react";
import { Icons } from "@/components/brand/icons";

export function StoryCanvas({ open, setOpen, prompt, story, users, reload }) {
  const [loading, setLoading] = useState(false);
  const [showAutoSaved, setShowAutoSaved] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState();
  const initialContent = [{}];
  const [authors, setAuthors] = useState([]);

  const content = story?.blocks
    ? story.blocks.length < 1
      ? initialContent
      : story.blocks
    : initialContent;

  function addAudioElement(blob) {
    saveRecording({ slug: story.slug, recording: blob })
      .then((res) => {
        editorRef.current.insertBlocks(
          [
            {
              type: "audio",
              props: {
                url: res.data.url,
              },
            },
          ],
          editorRef.current.document[editorRef.current.document.length - 1],
          "after"
        );
        toast.success("Recording saved");
      })
      .catch((err) => {
        toast.error("Failed to save recording. Retrying...");
        saveRecording({ slug: story.slug, recording: blob })
          .then((res) => {
            editorRef.current.insertBlocks(
              [
                {
                  type: "audio",
                  props: {
                    url: res.data.url,
                  },
                },
              ],
              editorRef.current.document[0],
              "before"
            );
            toast.success("Recording saved");
          })
          .catch((err) => {
            toast.error("Failed to save recording");
          });
      });
  }

  useEffect(() => {
    if (story) {
      setAuthors(
        story.people_ids?.map(
          (author) => users.find((user) => user.id === author)?.name
        ) || []
      );
    }
  }, [story]);

  // useDebounce(
  //   () => {
  //     if (!open) return;
  //     if (story && story.status !== "complete" && !loading) {
  //       updateStory({
  //         storyId: story.id,
  //         blocks: editorContent,
  //         authors: users
  //           .filter((user) => authors.includes(user.name))
  //           .map((user) => user.id),
  //         status: "pending",
  //       }).then(() => {
  //         renderAutoSavedUI();
  //       });
  //     }
  //   },
  //   5000,
  //   [editorContent, authors]
  // );

  const handleEditorContentChange = (content) => {
    setEditorContent(content);
  };

  function renderAutoSavedUI() {
    setShowAutoSaved(true);
    setTimeout(() => {
      setShowAutoSaved(false);
    }, 3000);
  }

  if (!story || !prompt) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 gap-2">
      <Sheet open={open} onOpenChange={() => setOpen(false)}>
        <SheetContent className="h-full" side="bottom">
          <SheetHeader>
            <SheetTitle className="sr-only">Write Story</SheetTitle>
            <SheetDescription className="sr-only">
              Write your story here. You can use markdown to format your text
            </SheetDescription>
          </SheetHeader>
          <div className="flex justify-center gap-4 py-4 bg-white h-full rounded-lg">
            <div className="w-full max-w-[900px] p-4 py-8 border rounded-lg flex flex-col overflow-y-auto">
              <div className="flex-grow">
                <div className="w-full flex justify-between px-4 md:px-14 items-center gap-8 flex-wrap">
                  <h2 className="text-2xl font-semibold">{prompt.title}</h2>
                  <VoiceRecorder
                    classes={{ AudioRecorderClass: "!bg-sky-400" }}
                    handleRecordingComplete={addAudioElement}
                  />
                </div>
                <Separator className="max-w-[900px] w-full px-4 md:px-14 my-8" />
                <Editor
                  endpoint={`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${story.slug}/add_image`}
                  initialContent={content}
                  onContentChange={handleEditorContentChange}
                  editable
                  editorRef={editorRef}
                />
              </div>

              <div>
                <div
                  className={`flex justify-end relative top-0 px-4 transition-opacity duration-500 ${
                    showAutoSaved ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <Badge variant="secondary">Auto Saved</Badge>
                </div>
              </div>

              <div className="px-4 md:px-14 flex justify-between w-full items-center flex-wrap">
                <div className="flex gap-4">
                  <div className="z-0 flex items-center -space-x-1 *:ring *:ring-background">
                    {authors.map((author) => {
                      const user = users.find((user) => user.name === author);
                      return (
                        <Tooltip key={user.id}>
                          <TooltipTrigger asChild>
                            <Avatar
                              className="z-10 size-8 bg-gray-100"
                              style={{ marginLeft: "-0.25rem" }}
                            >
                              <AvatarImage src={user.image} />
                              <AvatarFallback>{user.name[0]}</AvatarFallback>
                            </Avatar>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{user.name}</p>
                          </TooltipContent>
                        </Tooltip>
                      );
                    })}
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button className="rounded-full" variant="ghost">
                        Tag People
                        <Plus className="ml-2 w-4 h-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel>Tag People</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {users.map((user) => (
                        <DropdownMenuCheckboxItem
                          key={user.id}
                          checked={authors.includes(user.name)}
                          onCheckedChange={() => {
                            if (authors.includes(user.name)) {
                              setAuthors(
                                authors.filter((author) => author !== user.name)
                              );
                              return;
                            }
                            setAuthors([...authors, user.name]);
                          }}
                        >
                          {user.name}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="flex gap-4 items-center mt-4">
                  <Button
                    onClick={() =>
                      updateStory({
                        storyId: story.id,
                        blocks: editorContent,
                        authors: users
                          .filter((user) => authors.includes(user.name))
                          .map((user) => user.id),
                        status: "pending",
                      }).then(() => {
                        toast.success("Story saved successfully");
                      })
                    }
                    variant="ghost"
                  >
                    <Save className="mr-2 h-4 w-4" />
                    Save Progress
                  </Button>
                  <Button
                    onClick={() => {
                      setLoading(true);
                      updateStory({
                        storyId: story.id,
                        blocks: editorContent,
                        authors: users
                          .filter((user) => authors.includes(user.name))
                          .map((user) => user.id),
                        status: "complete",
                      }).then(() => {
                        setLoading(false);
                        setOpen(false);
                        reload();
                        toast.success("Story saved successfully");
                      });
                    }}
                    variant="default"
                    disabled={loading}
                  >
                    {loading ? (
                      <Icons.spinner className="mr-2 h-4 w-4" />
                    ) : (
                      <Sparkle className="mr-2 h-4 w-4" />
                    )}
                    I'm Finished
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
