import axios from "@/axios";

export function getStories({ personId, authored }) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}/stories?authored=${authored}`
  );
}

export function getPendingStories() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/pending`);
}

export function addPrompt(prompt) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts`, prompt);
}

export function getPrompts({ random = false, limit = null } = {}) {
  const params = new URLSearchParams();

  if (random) {
    params.append("random", "true");
  }

  if (limit !== null) {
    params.append("limit", limit);
  }

  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts`, {
    params: params,
  });
}

export function getUserPrompts(userId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts?user_id=${userId}`
  );
}

export function updateOrder(orderAttrs) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts/update_order`,
    {
      prompt_orders: orderAttrs,
    }
  );
}

export function writeStory(id) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/write`, {
    prompt_id: id,
  });
}

export function getUsers() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/users`);
}

export function getPeople() {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/inner_circle`
  );
}

export function createStory(prompt_id) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories`, {
    prompt_id,
  });
}

export function updateStory({ storyId, blocks, authors, status }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${storyId}`,
    {
      blocks,
      author_ids: authors,
      status,
    }
  );
}

export function getStory({ storyId }) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${storyId}`
  );
}

export function deletePrompt(id) {
  return axios.delete(`${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts/${id}`);
}

export function getStoriesByPrompt() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories`);
}

export function getStoriesByPromptId(id) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts/${id}/stories`
  );
}

export function togglePromptOfTheWeek(promptId, isCurrentlyPromptOfTheWeek) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts/${promptId}`,
    {
      prompt_of_the_week: !isCurrentlyPromptOfTheWeek, // Toggle the current status
    }
  );
}
