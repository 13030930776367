import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Mic, Pause } from "lucide-react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { LiveAudioVisualizer } from "react-audio-visualize";

export function VoiceRecorder({ handleRecordingComplete }) {
  const [liveMediaRecorder, setLiveMediaRecorder] = useState();
  const {
    mediaRecorder,
    startRecording,
    isRecording,
    togglePauseResume,
    isPaused,
    stopRecording,
    recordingBlob,
  } = useAudioRecorder();

  useEffect(() => {
    if (mediaRecorder) {
      setLiveMediaRecorder(mediaRecorder);
    }
  }, [mediaRecorder]);

  useEffect(() => {
    if (recordingBlob) {
      handleRecordingComplete(recordingBlob);
    }
  }, [recordingBlob]);

  function onClose() {
    if (isRecording) {
      stopRecording();
    }

    setLiveMediaRecorder(null);
  }

  return (
    <Drawer onClose={onClose}>
      <DrawerTrigger asChild>
        <Button variant="outline">
          <Mic className="mr-2 h-4 w-4" /> Use Audio
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm border rounded-lg mt-4">
          <DrawerHeader>
            <DrawerTitle>Record my story</DrawerTitle>
            <DrawerDescription>
              Click the record button below to start sharing your story.
            </DrawerDescription>
          </DrawerHeader>
          <div className="p-4 pb-0">
            <div className="flex items-center justify-center space-x-2">
              {isRecording ? (
                <div className="flex-1 text-center">
                  <div>
                    <Button
                      onClick={togglePauseResume}
                      className="w-20 h-20"
                      variant="ghost"
                    >
                      {isPaused ? (
                        <div className="w-12 h-12 bg-red-600 rounded-full"></div>
                      ) : (
                        <div className="w-12 h-12 bg-red-600 rounded-full flex justify-center items-center">
                          <Pause className="w-6 h-6 text-white" />
                        </div>
                      )}
                    </Button>
                  </div>
                  <div className="text-[0.70rem] uppercase text-muted-foreground">
                    {isPaused ? "Resume Recording" : "Pause Recording"}
                  </div>
                </div>
              ) : (
                <div className="flex-1 text-center">
                  <div className="text-7xl font-bold tracking-tighter">
                    <Button
                      onClick={startRecording}
                      className="w-20 h-20"
                      variant="ghost"
                    >
                      <div className="w-12 h-12 bg-red-600 rounded-full"></div>
                    </Button>
                  </div>
                  <div className="text-[0.70rem] uppercase text-muted-foreground">
                    Start Recording
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3 h-[120px]">
              {liveMediaRecorder && (
                <LiveAudioVisualizer
                  mediaRecorder={liveMediaRecorder}
                  width={320}
                  height={88}
                  barWidth={12}
                  barColor="rgb(111 134 101)"
                />
              )}
            </div>
          </div>
          <DrawerFooter>
            <DrawerClose asChild>
              <Button>I am finished recording</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
