import axios from "@/axios";

export function getPrompt(slug) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${slug}/prompt`
  );
}

export function saveStory({ slug, blocks, status }) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories`, {
    slug,
    blocks,
    status,
  });
}

export function saveStoryUnauthenticated({ slug, blocks, status, authors }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/save_story_unauthenticated`,
    {
      slug,
      blocks,
      status,
      author_ids: authors,
    }
  );
}

export function saveRecording({ slug, recording }) {
  const formData = new FormData();
  formData.append("file", recording);
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${slug}/add_recording`,
    formData
  );
}

export function getPeople(slug) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${slug}/people`
  );
}
