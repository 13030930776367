"use client";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { cn, featureEnabled } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";
import { useRouter } from "next/navigation";
import { useUser } from "@/components/providers/user-provider";
import { SkippedOnboarding } from "@/components/playbooks/cards/skipped-onboarding";
import { OrganizeMoney } from "@/components/playbooks/cards/organize-money";
import { DASHBOARD } from "@/config/features";
import { StoryOfWeek } from "@/components/dashboard/story-of-week";

export default function Home() {
  const { user } = useUser();
  const [activeItem, setActiveItem] = useState(null);
  const router = useRouter();

  useEffect(() => {
    if (featureEnabled(DASHBOARD, user)) {
      return;
    }
    router.push("/vault");
  }, [router]);

  return (
    <div className="relative h-full">
      <div
        className={cn(
          "p-4 lg:p-8 pt-6 h-full absolute top-0 left-0 right-0  transition-all duration-300 ease-in-out md:rounded-tl-lg md:rounded-bl-lg bg-background z-10 overflow-y-auto",
          activeItem ? "rounded-tr-lg rounded-br-lg right-[400px]" : "right-0",
          activeItem
            ? "overflow-y-hidden sm:overflow-y-auto"
            : "overflow-y-auto"
        )}
      >
        <div className="flex items-center justify-center flex-wrap flex-col w-full">
          <div className="flex justify-between w-full max-w-[900px]">
            <div className="flex text-[#9b9e99] gap-1 items-center w-full">
              <ReactSVG
                src={`/icons/home-line.svg`}
                beforeInjection={(svg) => {
                  const paths = svg.querySelectorAll("path");
                  paths.forEach((path) => {
                    path.setAttribute("stroke", "#9b9e99");
                  });
                  svg.setAttribute("width", 16);
                  svg.setAttribute("height", 16);
                }}
              />
              <h3 className="font-sans font-medium text-base">Dashboard</h3>
            </div>
            <div></div>
          </div>

          <div className="w-full max-w-[900px] flex flex-col gap-4 items-start">
            <div className="text-xl sm:text-2xl md:text-2xl mt-4 md:mt-8 font-semibold flex">
              <div className="animate-wave mr-2">👋</div> {getGreeting()},{" "}
              {user.first_name}
            </div>

            <Separator />
            {user.onboarding_status !== "completed" && <SkippedOnboarding />}
            <OrganizeMoney />
            <div className="flex gap-4 mt-12">
              <StoryOfWeek />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function getGreeting() {
  const now = new Date(); // Get the current date and time
  const hour = now.getHours(); // Get the current hour (0-23)

  if (hour >= 5 && hour < 12) {
    return "Good morning";
  } else if (hour >= 12 && hour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}
